<template>
  <router-view :key="$route.path" />
</template>

<route>
{
"meta": {
"label": "商品銷量報表"
}
}
</route>

<script>
export default {
  name: 'ProductReport',
  layout: 'manage'
}
</script>
